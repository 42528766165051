<template>
    <App title="Stacs Zero">
        <Block>
            <template #header>
                <h3 class="block-title">Stacs Zero</h3>
            </template>
            <Datatable :scopes="scopes" :options="options" />
        </Block>
    </App>
</template>

<script lang="ts">
import Block from '@/vue/components/Block.vue'
import Card from '@/vue/components/Card.vue'
import { CellRender, Column } from '@/vue/components/Datatable'
import Datatable from '@/vue/components/Datatable.vue'
import App from '@/vue/templates/App.vue'
import { Options, Vue } from 'vue-decorator'

@Options({
    components: {
        App,
        Card,
        Block,
        Datatable,
    },
})
export default class Group extends Vue {

    scopes = ["active", "completed"]

    get buttons() {
        let buttons: any[] = []
        buttons.push('refresh')
        buttons.push('trashed')

        return buttons
    }

    get columns() {
        let columns: any[] = []
        columns.push({
            data: 'id',
            orderable: true,
            name: 'id',
            title: 'Id',
        })
        columns.push({
            data: 'user.fullname',
            title: 'User',
        })
        columns.push({
            data: 'amount',
            title: 'Amount',
            render: CellRender.money(),
            orderable: true,
            name: 'amount'
        })

        columns.push({
            data: 'status',
            title: 'Status',
        })

        columns.push({
            data: 'due_at',
            title: 'Due',
            render: CellRender.date(),
        })

        columns.push({
            data: 'created_at',
            title: 'Requested',
            render: CellRender.date(),
            orderable: true,
            name: 'created_at'
        })

        columns.push({
            data: 'completed_at',
            title: 'Completed',
            render: CellRender.date(),
            orderable: true,
            name: 'completed_at'
        })

        columns.push(Column.actions(this.$gate, { update: false }))

        return columns
    }

    get options() {
        return {
            ajax: {
                url: this.route("zero.ajax.table"),
            },
            buttons: this.buttons,
            columns: this.columns,
            order: [[5, 'desc']]
        }
    }
}
</script>
