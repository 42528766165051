<template>
    <App title="Create Meta Variable">
        <template #top>
            <Alert ref="alert" class="alert-danger">
                {{ message }}
            </Alert>
        </template>
        <Block type="form">
            <template #header>
                <h3 class="block-title">Create A Meta Variable</h3>
            </template>
            <div class="row">
                <div class="col-12">
                    <Form ref="form" @submit="onSubmit">
                        <div class="row g-3">
                            <div class="col-12">
                                <SelectField id="name" v-model="name" name="name" rules="required" label="Name" placeholder="Name" :options="nameOptions">
                                    <option v-for="{ name, value } in metas" :key="value" :value="value">{{ name }}</option>
                                </SelectField>
                            </div>
                            <div class="col-12">
                                <SelectField id="type" v-model="type" name="type" rules="required" label="Type" placeholder="Type" :options="typeOptions">
                                    <option v-for="{ name, value } in types" :key="value" :value="value">{{ name }}</option>
                                </SelectField>
                            </div>
                            <div class="col-12">
                                <InputField id="data" v-model="data" name="data" rules="required" label="Data" placeholder="Data" />
                            </div>
                            <div class="col-12">
                                <DateField id="date" v-model="date" name="date" rules="required|date:DD-MM-YYYY" label="Date" placeholder="Date" />
                            </div>
                            <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                                <button class="btn btn-alt-secondary me-1 mb-3">Cancel</button>
                                <Button id="action" type="submit" :label="labels" :loading="loading" :done="done" class="btn btn-alt-primary me-1 mb-3" />
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </Block>
    </App>
</template>

<script lang="ts">

import { error } from '@/ts/app'
import Alert from '@/vue/components/Alert.vue'
import Block from '@/vue/components/Block.vue'
import { ButtonLabel } from '@/vue/components/Button'
import Button from '@/vue/components/Button.vue'
import DateField from '@/vue/components/DateField.vue'
import Form from '@/vue/components/Form.vue'
import InputField from '@/vue/components/InputField.vue'
import SelectField from '@/vue/components/SelectField.vue'
import App from '@/vue/templates/App.vue'
import { AxiosError } from 'axios'
import moment from 'moment'
import { Options as Select2Options } from 'select2'
import { Component, Prop, Ref, Vue, Watch, toNative } from 'vue-facing-decorator'

@Component({
    components: {
        Form,
        SelectField,
        Block,
        Button,
        App,
        InputField,
        Alert,
        DateField
    }
})
class Create extends Vue {

    message = ''

    loading = false
    done = false

    name = ''
    type = ''
    data = ''
    date = moment().format("DD-MM-YYYY")

    @Prop({ type: Array })
    readonly metas!: Array<{ name: string, value: string }>

    @Prop({ type: Array })
    readonly types!: Array<{ name: string, value: string }>

    @Ref('form')
    readonly form!: InstanceType<typeof Form>

    @Ref('alert')
    readonly alert!: InstanceType<typeof Alert>

    @Watch('message')
    onMessageChanged(value: string) {
        if (value.length > 0) {
            this.alert.show()
        } else {
            this.alert.hide()
        }
    }

    get labels(): ButtonLabel {
        return {
            normal: 'Create Meta Variable',
            loading: 'Creating....Meta Variable',
            done: 'Created Meta Variable'
        }
    }

    get typeOptions(): Select2Options {
        return {
            placeholder: 'Select Meta Type',
            minimumResultsForSearch: Infinity,
            //@ts-ignore
            ajax: null,
        }
    }

    get nameOptions(): Select2Options {
        return {
            placeholder: 'Select Meta Name',
            minimumResultsForSearch: Infinity,
            //@ts-ignore
            ajax: null,
        }
    }

    async onSubmit(payload) {
        this.loading = true
        this.$http
            .post(this.route('meta.ajax.create'), payload)
            .then(() => {
                this.done = true
                this.$router.visit(this.route('meta.page.group'))
            })
            .catch((e: AxiosError) => error(e, this))
            .finally(() => this.loading = false)
    }

}

export default toNative(Create)
</script>
