<template>
    <App title="Update Admin">
        <template #top>
            <Alert ref="alert" class="alert-danger">
                {{ message }}
            </Alert>
        </template>
        <Block type="form">
            <template #header>
                <h3 class="block-title">Update Admin</h3>
            </template>
            <div class="row">
                <div class="col-12">
                    <Form ref="form" @submit="onSubmit">
                        <div class="row g-3">
                            <div class="col-12">
                                <InputField id="firstname" v-model="firstname" name="firstname" rules="required" label="Firstname" placeholder="Firstname" />
                            </div>
                            <div class="col-12">
                                <InputField id="lastname" v-model="lastname" name="lastname" rules="required" label="Lastname" placeholder="Lastname" />
                            </div>
                            <div class="col-12">
                                <InputField id="email" v-model="email" name="email" rules="required|email" label="Email" type="email" placeholder="Email" />
                            </div>
                            <div class="col-12">
                                <PhoneField id="phone" v-model="phone" name="phone" rules="required|phone" label="Phone" placeholder="Phone" />
                            </div>
                            <div class="col-12">
                                <SelectField id="role" v-model="role" name="role" rules="required" label="Role" placeholder="Role" :options="options">
                                    <option v-for="role in roles" :key="role.value" :value="role.value">
                                        {{ role.name }}
                                    </option>
                                </SelectField>
                            </div>
                            <!--  <div class="col-12">
                                <InputField id="password" v-model="password" type="password" name="password" rules="required" label="Password" placeholder="Password" />
                            </div>
                            <div class="col-12">
                                <InputField id="password_confirmation" v-model="password_confirmation" type="password" name="password_confirmation" rules="required|confirmed:@password" label="Password Confirmation" placeholder="Password Confirmation" />
                            </div> -->
                            <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                                <button class="btn btn-alt-secondary me-1 mb-3">Cancel</button>
                                <Button id="action" type="submit" :label="labels" :loading="loading" :done="done" class="btn btn-alt-primary me-1 mb-3" />
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </Block>
    </App>
</template>

<script lang="ts">
import Admin, { Roles } from '@/models/Admin'
import { error } from '@/ts/app'
import Alert from '@/vue/components/Alert.vue'
import Block from '@/vue/components/Block.vue'
import { ButtonLabel } from "@/vue/components/Button"
import Button from '@/vue/components/Button.vue'
import Form from '@/vue/components/Form.vue'
import InputField from '@/vue/components/InputField.vue'
import PhoneField from '@/vue/components/PhoneField.vue'
import SelectField from '@/vue/components/SelectField.vue'
import App from '@/vue/templates/App.vue'
import { AxiosError } from 'axios'
import { Options as Select2Options } from 'select2'
import { Component, Prop, Ref, Vue, Watch, toNative } from 'vue-facing-decorator'

@Component({
    components: {
        App,
        Form,
        Alert,
        InputField,
        PhoneField,
        Button,
        Block,
        SelectField
    },
})
class Update extends Vue {
    message = ''

    firstname = ''
    lastname = ''
    email = ''
    phone = ''
    role = ''
    //password = ''
    //password_confirmation = ''

    loading = false
    done = false

    @Ref('form')
    readonly form!: InstanceType<typeof Form>

    @Ref('alert')
    readonly alert!: InstanceType<typeof Alert>

    @Prop({ type: Object })
    readonly admin !: Admin

    @Prop({ type: Array })
    readonly roles !: Roles[]

    @Watch('message')
    onMessageChanged(value: string) {
        if (value.length > 0) {
            this.alert.show()
        } else {
            this.alert.hide()
        }
    }

    get labels(): ButtonLabel {
        return {
            normal: 'Update Admin',
            loading: 'Updating....Admin',
            done: 'Updated Admin',
        }
    }

    get options(): Select2Options {
        return {
            placeholder: 'Select Role',
            minimumResultsForSearch: Infinity,
            //@ts-ignore
            ajax: null,
        }
    }

    created() {
        this.firstname = this.admin.firstname
        this.lastname = this.admin.lastname
        this.email = this.admin.email
        this.phone = this.admin.phone
        this.role = this.admin.role
    }

    async onSubmit(payload) {
        this.loading = true
        this.$http
            .post(this.route('admin.ajax.update'), payload)
            .then((response) => {
                this.done = true
                this.$router.visit((response.data.admin as Admin).url.single)
            })
            .catch((e: AxiosError) => error(e, this))
            .finally(() => this.loading = false)
    }
}

export default toNative(Update)
</script>
