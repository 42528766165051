<template>
    <App title="Meta Configurations">
        <div class="content">
            <Block type="data">
                <template #header>
                    <h3 class="block-title">Meta Configurations</h3>
                </template>

                <ul v-for="meta in metas" :key="meta.id" class="list-group list-group-flush">
                    <li class="list-group-item">
                        <div class="clearfix">
                            <span class="float-start">{{ meta.title }}</span>
                            <span class="float-end">{{ meta.data }}</span>
                        </div>
                    </li>
                </ul>
            </Block>
        </div>
    </App>
</template>

<script lang="ts">
import Meta from '@/models/Meta'
import Block from '@/vue/components/Block.vue'
import App from '@/vue/templates/App.vue'
import { } from 'vue-decorator'
import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

@Component({
    components: {
        App,
        Block,
    },
})
class Group extends Vue {

    @Prop({ default: () => [] })
    readonly metas!: Meta[]
}

export default toNative(Group)
</script>
