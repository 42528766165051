<template>
    <App title="Search Page">

    </App>
</template>

<script lang="ts">
import App from '@/vue/templates/App.vue'
import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'


@Component
class Search extends Vue {
    @Prop({ type: String })
    search!: string


    mounted() {

        this.$http.post('/search', { search: this.search }).then((response) => {
            console.log(response)
        }, (error) => {
            console.log(error)
        })
    }
}

export default toNative(Search)
</script>
