<template>
	<ul class="nav-main" :class="styles">
		<li v-for="(itemA, i) in menu.filter((i) => hasRole(i.role))" :key="i" :class="itemA.heading ? 'nav-main-heading' : 'nav-main-item'">
			<span v-if="itemA.heading">{{ itemA.label }}</span>
			<a v-else class="nav-main-link" :class="itemA.submenu ? 'nav-main-link-submenu' : ''" :href="route(itemA.name, itemA.query)" :data-toggle="itemA.submenu ? 'submenu' : ''">
				<i class="nav-main-link-icon" :class="itemA?.icon"></i>
				<span class="nav-main-link-name">{{ itemA.label }}</span>
			</a>
			<ul v-if="itemA.submenu" class="nav-main-submenu">
				<li v-for="(itemB, j) in itemA.submenu" :key="j" class="nav-main-item">
					<a class="nav-main-link" :class="itemB.submenu ? 'nav-main-link-submenu' : ''" :data-toggle="itemB.submenu ? 'submenu' : ''" aria-haspopup="true" aria-expanded="false" :href="route(itemB?.name, itemB.query)">
						<i class="nav-main-link-icon" :class="itemB?.icon"></i>
						<span class="nav-main-link-name">{{ itemB.label }}</span>
					</a>
					<ul v-if="itemB.submenu" class="nav-main-submenu">
						<li v-for="(itemC, k) in itemB.submenu" :key="k" class="nav-main-item">
							<a class="nav-main-link" :href="route(itemC.name, itemC.query)">
								<i class="nav-main-link-icon" :class="itemC?.icon"></i>
								<span class="nav-main-link-name">{{ itemC.label }}</span>
							</a>
						</li>
					</ul>
				</li>
			</ul>
		</li>
	</ul>
</template>

<script lang="ts">
import { hasRole, Role } from '@/models/Admin'
import { Component, Prop, toNative, Vue } from 'vue-facing-decorator'
import { RouteParams } from 'ziggy-js'
import { MenuItem } from './Menu'

@Component
class Menu extends Vue {
	@Prop({ type: Array })
	readonly menu!: MenuItem[]

	@Prop({ type: Boolean, default: false })
	readonly boxed!: boolean

	get styles() {
		return this.boxed ? 'nav-main-dark nav-main-horizontal nav-main-hover' : ''
	}

	link(name?: string, query?: RouteParams<string> | string, url?: string): string {
		if (typeof name !== 'undefined' && typeof query !== 'undefined') {
			return this.route(name, query)
		} else if (url) {
			return url
		} else {
			return '#'
		}
	}

	hasRole(role?: Role | Role[]) {
		return role ? hasRole(this.$user, role) : true
	}
}

export default toNative(Menu)
</script>

<style lang="scss"></style>
