<template>
    <App title="Pre-Qualifications">
        <Block>
            <template #header>
                <h3 class="block-title">Pre-Qualifications</h3>
            </template>
            <Datatable :scopes="scopes" :options="options" />
        </Block>
    </App>
</template>

<script lang="ts">
import Block from '@/vue/components/Block.vue'
import { CellRender, Column } from '@/vue/components/Datatable'
import Datatable from '@/vue/components/Datatable.vue'
import App from '@/vue/templates/App.vue'
import { Component, Vue, toNative } from 'vue-facing-decorator'

@Component({
    components: {
        App,
        Block,
        Datatable,
    },
})
class Group extends Vue {

    scopes = ["active", "pending"]

    get buttons() {
        let buttons: any[] = []
        buttons.push('refresh')
        buttons.push('trashed')

        return buttons
    }

    get columns() {
        let columns: any[] = []

        columns.push({
            data: 'id',
            orderable: true,
            name: 'id',
            title: 'Id',
        })
        columns.push({
            data: 'user.fullname',
            title: 'User',
        })

        columns.push({
            data: 'amount',
            title: 'Amount',
            render: CellRender.money()
        })

        columns.push({
            data: 'approval.status',
            title: 'Status',
            render: CellRender.approval()
        })

        columns.push({
            data: 'created_at',
            title: 'Submitted',
            render: CellRender.date(),
            orderable: true,
            name: 'created_at'
        })

        columns.push({
            data: 'expired_at',
            title: 'Expires',
            render: CellRender.date(),
            orderable: true,
            name: 'expired_at'
        })

        columns.push(Column.actions(this.$gate, { update: false }))


        return columns
    }

    get options() {
        return {
            ajax: {
                url: this.route('qualification.ajax.table'),
            },
            buttons: this.buttons,
            columns: this.columns,
            order: [[4, 'desc']]
        }
    }
}

export default toNative(Group)
</script>
