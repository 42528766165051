<template>
    <div class="mb-3">
        <span class="text-info d-block fs-6 w-100">{{ label }}</span>
        <span class="text-primary d-block fs-5 fs-md-4 w-100 text-break">
            <slot />
            <i v-if="!$slots.default || $slots?.default()[0]?.children?.length === 0" class="text-secondary">N/A</i>
        </span>
    </div>
</template>

<script lang="ts">
import { Options, Prop, Vue } from 'vue-decorator'

@Options({})
export default class TextBlock extends Vue {

    @Prop(String)
    readonly label!: string
}
</script>
