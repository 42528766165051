import Admin, { Role, hasRole } from '@/models/Admin'
import RNPL from '@/models/RNPL'

export default class RNPLPolicy {
	viewAny(admin: Admin) {
		return true
	}

	view(admin: Admin, rnpl: RNPL) {
		return true
	}

	create(admin: Admin) {
		return hasRole(admin, [Role.ADMIN, Role.PRODUCT])
	}

	update(admin: Admin, rnpl: RNPL) {
		return hasRole(admin, [Role.ADMIN, Role.PRODUCT])
	}

	restore(admin: Admin, rnpl: RNPL) {
		return hasRole(admin, [Role.ADMIN, Role.PRODUCT])
	}

	delete(admin: Admin, rnpl: RNPL) {
		return hasRole(admin, [Role.ADMIN, Role.PRODUCT])
	}

	forceDelete(admin: Admin, rnpl: RNPL) {
		return hasRole(admin, Role.ADMIN)
	}

	trash(admin: Admin) {
		return hasRole(admin, Role.ADMIN)
	}
}
