import Model from './Model'

export default interface Admin extends Model {
	fullname: string
	firstname: string
	lastname: string
	email: string
	phone: string
	role: Role
}

export enum Role {
	ADMIN = 'admin',
	PRODUCT = 'product',
	FINANCE = 'finance',
	OPERATIONS = 'operations',
	SALES = 'sales',
}

export interface Roles {
	name: string
	value: string
}

export function hasRole(admin: Admin, roles: Role | Role[]) {
	return roles instanceof Array ? roles.includes(admin.role) : admin.role === roles
}
