<template>
    <App title="Referrals">
        <Block>
            <template #header>
                <h3 class="block-title">Referrals</h3>
            </template>
            <Datatable :options="options" />
        </Block>
    </App>
</template>

<script lang="ts">
import Block from '@/vue/components/Block.vue'
import { Column } from '@/vue/components/Datatable'
import Datatable from '@/vue/components/Datatable.vue'
import App from '@/vue/templates/App.vue'
import { Component, Vue, toNative } from 'vue-facing-decorator'


@Component({
    components: {
        App,
        Block,
        Datatable,
    },
})
class Group extends Vue {
    get buttons() {
        let buttons: any[] = []
        buttons.push('refresh')
        return buttons
    }

    get columns() {
        let columns: any[] = []

        columns.push({
            data: 'id',
            orderable: true,
            name: 'id',
            title: 'Id',
        })

        columns.push({
            data: 'referrer.fullname',
            title: 'Referrer',
        })
        columns.push({
            data: 'referee.fullname',
            title: 'Referee',
        })

        columns.push(Column.actions(this.$gate, { update: false, view: false }))

        return columns
    }

    get options() {
        return {
            ajax: {
                url: this.route('referral.ajax.table'),
                method: 'POST',
            },
            buttons: this.buttons,
            columns: this.columns,
            order: false,
            searching: false
        }
    }
}

export default toNative(Group)
</script>
