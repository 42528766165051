<template>
    <App title="Setup Password">
        <template #top>
            <Alert ref="alert" class="alert-danger">
                {{ message }}
            </Alert>
        </template>
        <Block type="form">
            <template #header>
                <h3 class="block-title">Setup My Password</h3>
            </template>

            <Form ref="form" :initial-values="values" @submit="onSubmit">
                <div class="row g-3">
                    <div class="col-12">
                        <InputField id="password" v-model="password" name="password" rules="required" label="Password" type="password" placeholder="password" />
                    </div>
                </div>
                <div class="row g-3">
                    <div class="col-12">
                        <InputField id="password_confirmation" v-model="password_confirmation" type="password" name="password_confirmation" rules="required" label="Confirm Password" placeholder="confirm password" />
                    </div>
                </div>
                <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                    <Button id="action" type="submit" :label="labels" :loading="loading" :done="done" class="btn-lg btn-alt-primary" />
                </div>
            </Form>
        </Block>
    </App>
</template>

<script lang="ts">
import { error } from '@/ts/app'
import Alert from '@/vue/components/Alert.vue'
import Block from '@/vue/components/Block.vue'
import { ButtonLabel } from '@/vue/components/Button'
import Button from '@/vue/components/Button.vue'
import Field from '@/vue/components/Field.vue'
import Form from '@/vue/components/Form.vue'
import Input from '@/vue/components/Input.vue'
import App from '@/vue/templates/App.vue'
import { AxiosError } from 'axios'
import { Component, Prop, Ref, Vue, Watch, toNative } from 'vue-facing-decorator'

@Component({
    components: {
        App,
        Alert,
        Field,
        Input,
        Form,
        Button,
        Block
    },
})
class SetupPassword extends Vue {

    message = ''
    password = ''
    password_confirmation = ''
    loading = false
    done = false

    @Ref('form')
    readonly form!: InstanceType<typeof Form>

    @Ref('alert')
    readonly alert!: InstanceType<typeof Alert>

    @Prop({ type: String })
    readonly email!: string

    @Prop({ type: String })
    readonly otp!: string

    @Watch('message')
    onMessageChanged(value: string) {
        const alert: HTMLDivElement = this.alert.$el
        if (value.length > 0) {
            alert.classList.add('show')
        } else {
            alert.classList.remove('show')
        }
    }

    get values() {
        return {
            password: this.password,
            password_confirmation: this.password_confirmation,
        }
    }

    get labels(): ButtonLabel {
        return {
            normal: 'Submit',
            loading: 'Resetting Up Password',
            done: 'Done',
        } as ButtonLabel
    }

    get intended(): string {
        // @ts-ignore
        return this.$page.props?.url.intended ?? this.route('dashboard.page')
    }

    async onSubmit(payload) {
        this.loading = true
        this.message = ''
        this.$http
            .post(this.route('profile.ajax.password.setup'), payload)
            .then(() => {
                location.replace(this.intended)
            })
            .catch((e: AxiosError) => error(e, this))
            .finally(() => this.loading = false)
    }
}

export default toNative(SetupPassword)
</script>
