<template>
	<App title="Pre-Qualification">
		<div class="row">
			<div class="col">
				<Block type="data">
					<template #header>
						<h3 class="block-title">Pre-Qualification - #{{ qualification.id }}</h3>
					</template>
					<div class="row">
						<div class="col">
							<TextBlock label="Name">
								{{ qualification.user.fullname }}
							</TextBlock>
						</div>
					</div>
					<div class="row">
						<div class="col">
							<TextBlock label="Amount">
								{{ $render.money(qualification.amount) }}
							</TextBlock>
						</div>
						<div class="col">
							<TextBlock label="Status">
								{{ qualification.approval?.status }}
							</TextBlock>
						</div>
					</div>
					<div class="row">
						<div class="col">
							<TextBlock label="Submitted">
								{{ $render.date(qualification.created_at) }}
							</TextBlock>
						</div>
						<div class="col">
							<TextBlock label="Expires">
								{{ $render.date(qualification.expired_at) }}
							</TextBlock>
						</div>
					</div>
					<template #footer>
						<div class="row">
							<div class="col">
								<button v-if="qualification.financial" class="btn btn-outline-secondary btn-icon btn-lg me-2" @click="onOpenFinancial"><i class="fas fa-eye"></i> Financial Document</button>
								<button v-if="qualification.employment" class="btn btn-outline-secondary btn-icon btn-lg" @click="onOpenEmployment"><i class="fas fa-eye"></i> Employment Document</button>
							</div>
						</div>
					</template>
				</Block>
			</div>
		</div>
		<div class="row">
			<div class="col">
				<Block type="data">
					<template #header>
						<h3 class="block-title">Operations Approval</h3>
					</template>
					<div class="row">
						<div class="col-12">
							<div class="text-center mb-2">
								<div class="btn-group">
									<button :disabled="!operationsCanApprove" class="btn btn-success" @click="onApproval($event, 'approved')">Approve</button>
									<button :disabled="!operationsCanApprove" class="btn btn-danger" @click="onApproval($event, 'declined')">Decline</button>
								</div>
							</div>
						</div>
						<div class="col-12">
							<Textarea v-model="remarks.operation" :disabled="!operationsCanApprove" label="Decline Remarks (optional)" placeholder="Decline Remarks" />
						</div>
					</div>
				</Block>
			</div>
		</div>
		<div class="row">
			<div class="col">
				<Block type="data">
					<template #header>
						<h3 class="block-title">Transactions</h3>
					</template>
					<Datatable :options="options" />
				</Block>
			</div>
		</div>
	</App>
	<DocumentModal v-if="qualification.financial" ref="financial-modal" :media="qualification.financial" title="Financial Document" />
	<DocumentModal v-if="qualification.employment" ref="employment-modal" :media="qualification.employment" title="Employment Document" />
</template>

<script lang="ts">
import { Status } from '@/models/Approval'
import type Qualification from '@/models/Qualification'
import Block from '@/vue/components/Block.vue'
import { CellRender } from '@/vue/components/Datatable'
import Datatable from '@/vue/components/Datatable.vue'
import DocumentModal from '@/vue/components/DocumentModal.vue'
import Textarea from '@/vue/components/Textarea.vue'
import TextBlock from '@/vue/components/TextBlock.vue'
import App from '@/vue/templates/App.vue'
import { AxiosError } from 'axios'
import Swal from 'sweetalert2'
import { Component, Prop, Ref, Vue, toNative } from 'vue-facing-decorator'

@Component({
	components: {
		App,
		Block,
		TextBlock,
		Textarea,
		DocumentModal,
		Datatable,
	},
})
class Single extends Vue {
	@Ref('financial-modal')
	readonly financial_modal!: InstanceType<typeof DocumentModal>

	@Ref('employment-modal')
	readonly employment_modal!: InstanceType<typeof DocumentModal>

	@Prop({ type: Object })
	readonly qualification!: Qualification

	remarks = {
		operation: '',
	}

	get hasOperationApproval() {
		return this.qualification.approval?.status !== 'pending'
	}

	get approved() {
		return this.qualification.approval.status !== Status.PENDING
	}

	get operationsCanApprove() {
		return !this.hasOperationApproval //&& !this.hasFinanceApproval
	}

	onOpenFinancial() {
		this.financial_modal.onShow()
	}

	onOpenEmployment() {
		this.employment_modal.onShow()
	}

	onApproval(e: Event, status: string) {
		e.preventDefault()
		const button = e.target as HTMLButtonElement
		button.disabled = true
		const label = button.innerHTML
		button.innerHTML = '<i class="fas fa-spinner fa-spin"></i>'
		const payload = {
			status,
			type: 'operation',
			remarks: this.remarks.operation,
		}
		this.$sweet
			.fire({
				title: 'Are You Sure?',
				text: "You won't be able to revert this!",
				icon: 'warning',

				showCancelButton: true,
				confirmButtonText: 'YES',
			})
			.then((result) => {
				if (result.isConfirmed) {
					this.$http
						.patch(this.route('qualification.ajax.approval', { qualification: this.qualification.id }), payload)
						.then(() => {
							this.$router.reload({ only: ['qualification'] })
							button.innerHTML = 'Done'
						})
						.catch((e: AxiosError) => {
							this.$error(e)
							button.innerHTML = label
							// @ts-ignore
							Swal.fire(e.response?.data?.message)
						})
						.finally(() => (button.disabled = false))
				} else {
					button.disabled = false
					button.innerHTML = label
				}
			})
	}

	get buttons() {
		let buttons: any[] = []
		buttons.push('refresh')
		buttons.push('trashed')

		return buttons
	}

	get columns() {
		let columns: any[] = []
		columns.push({
			data: 'id',
			orderable: true,
			name: 'id',
			title: 'Id',
		})

		columns.push({
			data: 'amount',
			title: 'Amount',
			orderable: true,
			name: 'amount',
			render: CellRender.money(),
		})

		columns.push({
			data: 'type',
			title: 'Type',
			orderable: true,
			name: 'name',
			render: CellRender.payment(),
		})

		columns.push({
			data: 'status',
			title: 'Status',
			orderable: true,
			name: 'status',
			render: CellRender.status(),
		})

		columns.push({
			data: 'narration',
			title: 'Narration',
			orderable: true,
			name: 'narration',
		})

		columns.push({
			data: 'created_at',
			title: 'Created',
			orderable: true,
			name: 'created_at',
			render: CellRender.date(),
		})

		columns.push({
			data: 'verified_at',
			title: 'Verified',
			orderable: true,
			name: 'verified_at',
			render: CellRender.date(),
		})

		return columns
	}

	get options() {
		return {
			ajax: {
				url: this.route('qualification.ajax.transactions.table', { qualification: this.qualification.id }),
				method: 'POST',
			},
			buttons: this.buttons,
			columns: this.columns,
		}
	}
}

export default toNative(Single)
</script>
