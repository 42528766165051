<template>
    <!-- <Grapes /> -->
    <Builder />
</template>
<script lang="ts">
import Builder from '@/vue/components/Builder.vue'
import Grapes from '@/vue/components/Grapes.vue'
import { Component, Vue, toNative } from 'vue-facing-decorator'

@Component({
    components: {
        Grapes,
        Builder
    }
})
class Test extends Vue {

}
export default toNative(Test)
</script>
