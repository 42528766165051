import Model from './Model'

export interface Approval extends Model {
	status: Status
	type: Type
	remarks: string
	approved_at: string
}

export enum Status {
	APPROVED = 'approved',
	PENDING = 'pending',
	DECLINED = 'declined',
}

export enum Type {
	FINANCE = 'finance',
	OPERATION = 'operation',
}
