<template>
    <button class="btn" :class="style" @click="toggleSuspension">
        {{ isSuspended ? "Resume" : "Suspend" }}
    </button>
</template>

<script lang="ts">
import { Component, Vue, toNative, Prop, Emit } from "vue-facing-decorator"

@Component({
    emits: ["suspension"]
})
class SuspensionButton extends Vue {

    @Prop({ type: Boolean, default: false })
    readonly suspended_at!: string

    get isSuspended() {
        return !!this.suspended_at
    }

    get style() {
        return this.isSuspended ? "btn-danger" : "btn-secondary"
    }

    @Emit("suspension")
    toggleSuspension() {
        return !this.isSuspended
    }

}

export default toNative(SuspensionButton)
</script>

<style scoped lang="scss"></style>
