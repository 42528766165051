<template>
    <App title="Meta Configurations">
        <Block type="content">
            <template #header>
                <h3 class="block-title">Meta Data</h3>
            </template>
            <Datatable :options="options" />

            <!--  <ul v-for="meta in metas" :key="meta.id" class="list-group w-50">
                <li class="list-group-item">
                    <div class="d-flex justify-content-around">
                        <div class="text-start bg-whi"> <span>{{ meta.title }}</span></div>
                        <div class="text-end"> <span>{{ meta.data }}</span></div>
                    </div>
                </li>
            </ul> -->
        </Block>
    </App>
</template>

<script lang="ts">
import Meta from '@/models/Meta'
import Block from '@/vue/components/Block.vue'
//import List from '@/vue/components/List.vue'
import { CellRender, Column } from '@/vue/components/Datatable'
import Datatable from '@/vue/components/Datatable.vue'
import App from '@/vue/templates/App.vue'
import { Config } from 'datatables.net-bs5'
import { } from 'vue-decorator'
import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

@Component({
    components: {
        App,
        Block,
        //List
        Datatable,
    },
})
class Group extends Vue {

    @Prop({ default: () => [] })
    readonly metas!: Meta[]

    get buttons() {
        let buttons: any[] = []
        buttons.push('refresh')
        buttons.push({
            extend: "create",
            attr: {
                href: this.route("meta.page.create"),
            },
        })
        buttons.push('trashed')

        return buttons
    }

    get columns() {
        let columns: any[] = []
        columns.push({
            data: 'id',
            orderable: true,
            name: 'id',
            title: 'Id',
        })

        columns.push({
            data: 'title',
            title: 'Name',
            name: 'name',
            orderable: true,
        })

        columns.push({
            data: 'type',
            title: 'Type',
            name: 'type',
            orderable: true,
        })

        columns.push({
            data: 'data',
            title: 'Value',
            name: 'value',
            orderable: true,
        })

        columns.push({
            data: 'created_at',
            title: 'Created',
            name: 'created_at',
            render: CellRender.date(),
            orderable: true,
        })

        columns.push(Column.actions(this.$gate, { update: false, view: false }))

        return columns
    }

    get options(): Config {
        return {
            ajax: {
                url: this.route('meta.ajax.table'),
                method: 'POST',
            },
            buttons: this.buttons,
            columns: this.columns,
            order: [[3, 'desc']],
        }
    }
}

export default toNative(Group)
</script>
