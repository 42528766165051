<template>
    <App title="Dashboard">
        <div class="content">
            <h1 class="content-heading">Users</h1>
            <div class="row">
                <CounterAsync title="Users with Easy Stacs" icon="fas fa-vault" :url="url({ type: 'users', data: 'easies' })" />
                <CounterAsync title="Users with Stacs Vault" icon="fas fa-piggy-bank" :url="url({ type: 'users', data: 'vaults' })" />
                <CounterAsync title="Active Users" icon="fas fa-user" :url="url({ type: 'users', data: 'active' })" />
                <CounterAsync title="Users with Stacs Zero" icon="fas fa-money-check" :url="url({ type: 'users', data: 'zeros' })" />
                <CounterAsync title="Users with Stacs RNPL" icon="fas fa-house-chimney" :url="url({ type: 'users', data: 'rnpls' })" />
                <CounterAsync title="Total Users" icon="far fa-user" :url="url({ type: 'users', data: 'total' })" />
            </div>
            <h1 class="content-heading">Finance</h1>
            <div class="row">
                <CounterAsync title="Easy Stacs Deposit" icon="fas fa-piggy-bank" :url="url({ type: 'money', data: 'easies' })" type="money" />
                <CounterAsync title="Stacs Vault Deposit" icon="fas fa-vault" :url="url({ type: 'money', data: 'vaults' })" type="money" />
                <CounterAsync title="ZERO Credits" icon="fas fa-money-check" :url="url({ type: 'money', data: 'zeros' })" type="money" />
                <CounterAsync title="RNPL Credits" icon="fas fa-house-chimney" :url="url({ type: 'money', data: 'rnpls' })" type="money" />
                <CounterAsync title="Wallet Deposited" icon="fas fa-wallet" :url="url({ type: 'money', data: 'wallet' })" type="money" />
            </div>
        </div>
    </App>
</template>
<script lang="ts">
import Chart from '@/vue/components/Chart.vue'
import Counter from '@/vue/components/Counter.vue'
import CounterAsync from '@/vue/components/CounterAsync.vue'
import App from '@/vue/templates/App.vue'
import { Component, Vue, toNative } from 'vue-facing-decorator'

@Component({
    components: {
        App,
        Chart,
        Counter,
        CounterAsync,
    },
})
class Dashboard extends Vue {

    results = {
        zero_users: 0,
        zero_amount: 0,
        rnpl_users: 0,
        rnpl_amount: 0
    }

    get money_options() {
        return {
            prefix: '₦',
            decimalPlaces: 2,
        }
    }

    url(query) {
        return this.route('dashboard.ajax.counters', query)
    }

}

export default toNative(Dashboard)
</script>
