import Easy from './Easy'
import Model from './Model'
import { MoneyData } from './Money'
import User from './User'
import Vault from './Vault'

export default interface Transaction extends Model {
	user: User
	type: Type
	transactable_id: number
	transactable_type: string
	verified_at?: string
	status: Status
	amount: MoneyData
	transactable: Vault | Easy
}

export enum Type {
	CREDIT = 'credit',
	DEBIT = 'debit',
	CHARGE = 'charge',
	AUTO = 'auto',
	PENALTY = 'penalty',
}

export enum Status {
	PENDING = 'pending',
	SUCCESSFUL = 'successful',
	FAILED = 'failed',
}

export const TransactionTypeLabel = (type: Type) => {
	switch (type) {
		case Type.CREDIT:
			return 'CREDIT'
		case Type.DEBIT:
			return 'DEBIT'
		case Type.PENALTY:
			return 'PENALTY'
		case Type.CHARGE:
			return 'CHARGE'
	}
}
