<template>
    <App title="Stacs Zero">
        <div class="row">
            <div class="col">
                <Block type="data">
                    <template #header>
                        <h1 class="block-title">Zero - #{{ zero.id }}</h1>
                    </template>
                    <div class="row">
                        <div class="col">
                            <TextBlock label="User">
                                {{ zero.user.fullname }}
                            </TextBlock>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4">
                            <TextBlock label="Amount">
                                {{ $render.money(zero.amount) }}
                            </TextBlock>
                        </div>
                        <div class="col-4">
                            <TextBlock label="Penalty Charge">
                                {{ $render.money(zero.penalty) }}
                            </TextBlock>
                        </div>
                        <div class="col-4">
                            <TextBlock label="Premium">
                                {{ $render.money(zero.premium) }}
                            </TextBlock>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4">
                            <TextBlock label="Created">
                                {{ $render.date(zero.created_at) }}
                            </TextBlock>
                        </div>
                        <div class="col-4">
                            <TextBlock label="Due Date">
                                {{ $render.date(zero.due_at) }}
                            </TextBlock>
                        </div>
                        <div class="col-4">
                            <TextBlock label="Completed">
                                {{ $render.date(zero.completed_at) }}
                            </TextBlock>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4">
                            <TextBlock label="Default">
                                {{ $render.bool(zero.is_default) }}
                            </TextBlock>
                        </div>
                        <div class="col-4">
                            <TextBlock label="Active">
                                {{ $render.bool(zero.active) }}
                            </TextBlock>
                        </div>
                    </div>
                </Block>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <Block type="data">
                    <template #header>
                        <h3 class="block-title">Transactions</h3>
                    </template>
                    <Datatable :options="options" />
                </Block>
            </div>
        </div>
    </App>
</template>
<script lang="ts">
import type Zero from '@/models/ZERO'
import Block from '@/vue/components/Block.vue'
import { CellRender } from '@/vue/components/Datatable'
import Datatable from '@/vue/components/Datatable.vue'
import TextBlock from '@/vue/components/TextBlock.vue'
import App from '@/vue/templates/App.vue'
import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

@Component({
    components: {
        App,
        Block,
        TextBlock,
        Datatable,
    },
})
class Single extends Vue {

    @Prop({ type: Object })
    readonly zero!: Zero

    get buttons() {
        let buttons: any[] = []
        buttons.push('refresh')
        buttons.push('trashed')

        return buttons
    }

    get columns() {
        let columns: any[] = []
        columns.push({
            data: 'id',
            orderable: true,
            name: 'id',
            title: 'Id',
        })

        columns.push({
            data: 'amount',
            title: 'Amount',
            orderable: true,
            name: 'amount',
            render: CellRender.money()
        })

        columns.push({
            data: 'type',
            title: 'Type',
            orderable: true,
            name: 'name',
            render: CellRender.payment()
        })

        columns.push({
            data: 'status',
            title: 'Status',
            orderable: true,
            name: 'status',
            render: CellRender.status()
        })

        columns.push({
            data: 'narration',
            title: 'Narration',
            orderable: true,
            name: 'narration'
        })

        columns.push({
            data: 'created_at',
            title: 'Created',
            orderable: true,
            name: 'created_at',
            render: CellRender.date()
        })

        columns.push({
            data: 'verified_at',
            title: 'Verified',
            orderable: true,
            name: 'verified_at',
            render: CellRender.date()
        })

        return columns
    }

    get options() {
        return {
            ajax: {
                url: this.route('zero.ajax.transactions.table', { zero: this.zero.id }),
                method: 'POST',
            },
            buttons: this.buttons,
            columns: this.columns,
        }
    }
}

export default toNative(Single)
</script>
