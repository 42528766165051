<template>
    <App title="Easy Stacs Plan">
        <Block>
            <template #header>
                <h3 class="block-title">Easy Stacs Plans</h3>
            </template>
            <Datatable :scopes="scopes" :options="options" />
        </Block>
    </App>
</template>

<script lang="ts">
import Block from '@/vue/components/Block.vue'
import { CellRender, Column } from '@/vue/components/Datatable'
import Datatable from '@/vue/components/Datatable.vue'
import App from '@/vue/templates/App.vue'
import { Component, Vue, toNative } from 'vue-facing-decorator'

@Component({
    components: {
        App,
        Block,
        Datatable,
    },
})
class Group extends Vue {

    scopes = ["active", "completed"]

    get buttons() {
        let buttons: any[] = []
        buttons.push('refresh')
        buttons.push('trashed')
        buttons.push('searchBuilder')

        return buttons
    }

    get columns() {
        let columns: any[] = []
        columns.push({
            data: 'id',
            orderable: true,
            name: 'id',
            title: 'Id',
        })
        columns.push({
            data: 'title',
            title: 'Title',
            orderable: true,
            name: 'title',
            render: CellRender.title()
        })

        columns.push({
            data: 'user.fullname',
            title: 'User',
        })
        columns.push({
            data: 'amount',
            title: 'Capital',
            render: CellRender.money(),
            name: 'amount',
            orderable: true,
        })

        columns.push({
            data: 'balance',
            title: 'Balance',
            render: CellRender.money()
        })

        columns.push({
            data: 'created_at',
            title: 'Opened',
            name: 'created_at',
            render: CellRender.date(),
            orderable: true,
        })

        columns.push({
            data: 'yielded_at',
            title: 'Matured',
            render: CellRender.date(),
        })
        columns.push({
            data: 'completed_at',
            title: 'Closed',
            name: 'completed_at',
            render: CellRender.date(),
            orderable: true,
        })

        columns.push(Column.actions(this.$gate, { update: false }))

        return columns
    }

    get options() {
        return {
            ajax: {
                url: this.route('easy.ajax.table'),
                method: 'POST',
            },
            buttons: this.buttons,
            columns: this.columns,
            order: [[5, 'desc']],
        }
    }
}

export default toNative(Group)
</script>
