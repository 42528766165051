<template>
    <App title="RNPL Application">
        <div class="row">
            <div class="col">
                <Block type="data">
                    <template #header>
                        <h3 class="block-title">RNPL Information - #{{ rnpl.id }}</h3>
                    </template>
                    <div class="row">
                        <div class="col-8">
                            <TextBlock label="User">
                                {{ rnpl.user.fullname }} ({{ rnpl.user.email }})
                            </TextBlock>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4">
                            <TextBlock label="Amount">
                                {{ $render.money(rnpl.amount) }}
                            </TextBlock>
                        </div>
                        <div class="col-4">
                            <TextBlock label="Total Amount Paid">
                                {{ $render.money(rnpl.paid) }}
                            </TextBlock>
                        </div>
                        <div class="col-4">
                            <TextBlock label="Outstanding Amount">
                                {{ $render.money(rnpl.outstanding) }}
                            </TextBlock>
                        </div>
                        <div class="col-4">
                            <TextBlock label="Premium">
                                {{ $render.money(rnpl.premium) }}
                            </TextBlock>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4">
                            <TextBlock label="Active">
                                {{ rnpl?.is_active }}
                            </TextBlock>
                        </div>
                        <div class="col-4">
                            <TextBlock label="Due">
                                {{ rnpl?.is_due }}
                            </TextBlock>
                        </div>
                        <div class="col-4">
                            <TextBlock label="Default">
                                {{ rnpl?.is_default }}
                            </TextBlock>
                        </div>
                        <div class="col-4">
                            <TextBlock label="Repayments">
                                {{ rnpl.payments_done }}/12
                            </TextBlock>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4">
                            <TextBlock label="Application Date">
                                {{ $render.date(rnpl.created_at) }}
                            </TextBlock>
                        </div>
                        <div class="col-4">
                            <TextBlock label="Next Payment date">
                                {{ $render.date(rnpl.due_at) }}
                            </TextBlock>
                        </div>
                        <div class="col-4">
                            <TextBlock label="Last Payment Date">
                                {{ $render.date(rnpl.last_payment_at) }}
                            </TextBlock>
                        </div>
                        <div class="col-4">
                            <TextBlock label="Closed Date">
                                {{ $render.date(rnpl.completed_at) }}
                            </TextBlock>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <TextBlock label="Address">
                                {{ rnpl.address?.full_address }}
                            </TextBlock>
                        </div>
                        <div class="col-4">
                            <TextBlock label="Apartment Type">
                                {{ rnpl.facility }}
                            </TextBlock>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4">
                            <TextBlock label="Representative Name">
                                {{ rnpl.manager.name }}
                            </TextBlock>
                        </div>
                        <div class="col-4">
                            <TextBlock label="Representative Phone">
                                {{ rnpl.manager.phone }}
                            </TextBlock>
                        </div>
                        <div class="col-4">
                            <TextBlock label="Representative Type">
                                {{ rnpl.manager.type }}
                            </TextBlock>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <TextBlock label="Operations Approval Status">
                                {{ rnpl.operation.status }}
                            </TextBlock>
                        </div>
                        <div class="col-6">
                            <TextBlock label="Operations Approval Date">
                                {{ $render.date(rnpl.operation.approved_at) }}
                            </TextBlock>
                        </div>
                        <div class="col-6">
                            <TextBlock label="Finance Approval Status">
                                {{ rnpl.finance.status }}
                            </TextBlock>
                        </div>
                        <div class="col-6">
                            <TextBlock label="Finance Approval Date">
                                {{ $render.date(rnpl.finance.approved_at) }}
                            </TextBlock>
                        </div>
                    </div>
                    <template #footer>
                        <div class="row">
                            <div class="col">
                                <button class="btn btn-outline-secondary btn-icon btn-lg me-2" @click="onOpenFinancial">
                                    <i class="fas fa-eye"></i> Financial Document
                                </button>
                                <button class="btn btn-outline-secondary btn-icon btn-lg" @click="onOpenEmployment">
                                    <i class="fas fa-eye"></i> Employment Document
                                </button>
                            </div>
                        </div>
                    </template>
                </Block>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <Block type="data">
                    <template #header>
                        <h3 class="block-title">Operations Approval</h3>
                    </template>
                    <div class="row">
                        <div class="col-12">
                            <div class="text-center mb-2">
                                <div class="btn-group">
                                    <button :disabled="!operationsCanApprove" class="btn btn-success" @click="onApproval($event, 'operation', 'approved')">Approve</button>
                                    <button :disabled="!operationsCanApprove" class="btn btn-danger" @click="onApproval($event, 'operation', 'declined')">Decline</button>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <Textarea v-model="remarks.operation" :disabled="!operationsCanApprove" label="Decline Remarks (optional)" placeholder="Decline Remarks" />
                        </div>
                    </div>
                </Block>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <Block type="data">
                    <template #header>
                        <h3 class="block-title">Finance Approval</h3>
                    </template>
                    <div class="row">
                        <div class="col-12">
                            <div class="text-center mb-2">
                                <div class="btn-group">
                                    <button :disabled="!financeCanApprove" class="btn btn-success" @click="onApproval($event, 'finance', 'approved')">Approve</button>
                                    <button :disabled="!financeCanApprove" class="btn btn-danger" @click="onApproval($event, 'finance', 'declined')">Decline</button>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <Textarea v-model="remarks.finance" :disabled="!financeCanApprove" label="Decline Remarks (optional)" placeholder="Decline Remarks" />
                        </div>
                    </div>
                </Block>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <Block type="data">
                    <template #header>
                        <h3 class="block-title">Transactions</h3>
                    </template>
                    <Datatable :options="options" />
                </Block>
            </div>
        </div>
    </App>
    <DocumentModal ref="financial-modal" :media="rnpl.financial" title="Financial Document" />
    <DocumentModal ref="employment-modal" :media="rnpl.employment" title="Employment Document" />
</template>
<script lang="ts">
import { Type } from '@/models/Approval'
import type RNPL from '@/models/RNPL'
import { error } from '@/ts/app'
import Block from '@/vue/components/Block.vue'
import Card from '@/vue/components/Card.vue'
import { CellRender } from '@/vue/components/Datatable'
import Datatable from '@/vue/components/Datatable.vue'
import DocumentModal from '@/vue/components/DocumentModal.vue'
import TextBlock from '@/vue/components/TextBlock.vue'
import Textarea from '@/vue/components/Textarea.vue'
import App from '@/vue/templates/App.vue'
import { AxiosError } from 'axios'
import Swal from 'sweetalert2'
import { Component, Prop, Ref, Vue, toNative } from 'vue-facing-decorator'

@Component({
    components: {
        App,
        Card,
        Block,
        TextBlock,
        Textarea,
        Datatable,
        DocumentModal
    }
})
class Single extends Vue {

    @Ref("financial-modal")
    readonly financial_modal !: InstanceType<typeof DocumentModal>

    @Ref("employment-modal")
    readonly employment_modal !: InstanceType<typeof DocumentModal>

    @Prop({ type: Object })
    rnpl!: RNPL

    loading = {
        approve: false,
        decline: false
    }

    remarks = {
        finance: '',
        operation: ''
    }

    @Prop({ type: String })
    readonly id!: string

    @Ref("app")
    readonly app!: HTMLDivElement

    created() {
        //this.getRNPL()
    }

    get hasOperationApproval() {
        return this.rnpl.operation?.status !== 'pending'
    }

    get hasFinanceApproval() {
        return this.rnpl.finance?.status !== 'pending'
    }

    get operationsCanApprove() {
        return !this.hasOperationApproval //&& !this.hasFinanceApproval
    }

    get financeCanApprove() {
        return this.hasOperationApproval && !this.hasFinanceApproval
    }

    onOpenFinancial() {
        this.financial_modal.onShow()
    }

    onOpenEmployment() {
        this.employment_modal.onShow()
    }

    onApproval(event: Event, type: string, status: string) {
        event.preventDefault()
        const button = event.target as HTMLButtonElement
        button.disabled = true
        const label = button.innerHTML
        button.innerHTML = '<i class="fas fa-spinner fa-spin"></i>'


        let remarks = ''
        switch (type) {
            case Type.OPERATION:
                remarks = this.remarks.operation
                break
            case Type.FINANCE:
                remarks = this.remarks.finance
                break
        }

        const payload = {
            status,
            type,
            remarks
        }

        this.$sweet.fire({
            title: 'Are You Sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',

            showCancelButton: true,
            confirmButtonText: 'YES'
        }).then((result) => {
            if (result.isConfirmed) {
                this.$http
                    .patch(this.route("rnpl.ajax.approval", { rnpl: this.rnpl.id }), payload)
                    .then(() => (button.innerHTML = "Done", this.$router.reload({ only: ['rnpl'] })))
                    .catch((e: AxiosError) => {
                        error(e)
                        button.innerHTML = label
                        // @ts-ignore
                        Swal.fire(e.response?.data?.message)
                    })
                    .finally(() => (button.disabled = false))
            } else {
                button.disabled = false
                button.innerHTML = label
            }
        })
    }



    get buttons() {
        let buttons: any[] = []
        buttons.push('refresh')
        buttons.push('trashed')

        return buttons
    }

    get columns() {
        let columns: any[] = []
        columns.push({
            data: 'id',
            orderable: true,
            name: 'id',
            title: 'Id',
        })

        columns.push({
            data: 'amount',
            title: 'Amount',
            orderable: true,
            name: 'amount',
            render: CellRender.money()
        })

        columns.push({
            data: 'type',
            title: 'Type',
            orderable: true,
            name: 'name',
            render: CellRender.payment()
        })

        columns.push({
            data: 'status',
            title: 'Status',
            orderable: true,
            name: 'status',
            render: CellRender.status()
        })

        columns.push({
            data: 'narration',
            title: 'Narration',
            orderable: true,
            name: 'narration'
        })

        columns.push({
            data: 'created_at',
            title: 'Created',
            orderable: true,
            name: 'created_at',
            render: CellRender.date()
        })

        columns.push({
            data: 'verified_at',
            title: 'Verified',
            orderable: true,
            name: 'verified_at',
            render: CellRender.date()
        })

        return columns
    }

    get options() {
        return {
            ajax: {
                url: this.route('rnpl.ajax.transactions.table', { rnpl: this.rnpl.id }),
                method: 'POST',
            },
            buttons: this.buttons,
            columns: this.columns,
        }
    }

}
export default toNative(Single)
</script>

<style lang="scss">
.content p,
.content .push,
.content .block,
.content .items-push>div {
    margin-bottom: 10px;
}
</style>
