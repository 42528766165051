<template>
    <App title="Users">
        <div class="row">
            <div class="col">
                <Block>
                    <template #header>
                        <h3 class="block-title">Users</h3>
                    </template>
                    <Datatable :scopes="scopes" :options="options" />
                </Block>
            </div>
        </div>
    </App>
</template>

<script lang="ts">
import Block from '@/vue/components/Block.vue'
import { CellRender, Column, SearchBuilder } from '@/vue/components/Datatable'
import Datatable from '@/vue/components/Datatable.vue'
import App from '@/vue/templates/App.vue'
import { Config } from 'datatables.net-bs5'
import { Component, Vue, toNative } from 'vue-facing-decorator'

@Component({
    components: {
        App,
        Block,
        Datatable,
    },
})
class Group extends Vue {

    scopes = ['active', 'suspended']

    get buttons() {
        let buttons: any[] = []
        buttons.push('refresh')
        buttons.push('trashed')
        buttons.push({
            extend: 'searchBuilder',
            config: {
                ...SearchBuilder,
                columns: [0, 1, 3, 4, 5]
            }
        })

        return buttons
    }

    get columns() {
        let columns: any[] = []
        columns.push({
            data: 'id',
            orderable: true,
            name: 'id',
            title: 'Id',
            type: 'num'
        })

        columns.push({
            orderable: true,
            name: 'tag',
            data: 'tag',
            title: 'Tag',
            type: 'string'
        })

        columns.push({
            data: 'fullname',
            title: 'Name',
            orderable: true
        })
        columns.push({
            data: 'email',
            title: 'email',
            orderable: true,
            name: 'email',
            type: 'string'
        })

        columns.push({
            data: 'phone',
            title: 'Phone',
            render: CellRender.phone(),
            name: 'phone',
            orderable: true,
            type: 'string'
        })

        columns.push({
            data: 'suspended_at',
            title: 'Status',
            render: (data) => {
                if (data) {
                    return '<span class="text-danger">Suspended</span>'
                } else {
                    return '<span class="text-success">Active</span>'
                }
            },
            orderable: true,
            name: 'suspended_at',
            type: 'date'
        })

        columns.push({
            data: 'created_at',
            title: 'Registered',
            render: CellRender.date(),
            orderable: true,
            name: 'created_at',
            type: 'date'
        })

        columns.push(Column.actions(this.$gate, { update: false }))

        return columns
    }

    get options(): Config {
        return {
            ajax: {
                url: this.route('user.ajax.table'),
                method: 'POST',
            },
            buttons: this.buttons,
            columns: this.columns,
            order: [[6, 'desc']],
        }
    }
}

export default toNative(Group)
</script>
