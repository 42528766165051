<template>
    <App title="Transactions (Credit)">
        <Block>
            <template #header>
                <h3 class="block-title">Transactions (Credit)</h3>
            </template>
            <Datatable :options="options" />
        </Block>
    </App>
</template>

<script lang="ts">
import { Status, TransactionTypeLabel, Type } from '@/models/Transaction'
import Block from '@/vue/components/Block.vue'
import { CellRender } from '@/vue/components/Datatable'
import Datatable from '@/vue/components/Datatable.vue'
import App from '@/vue/templates/App.vue'
import { Component, Vue, toNative } from 'vue-facing-decorator'

@Component({
    components: {
        App,
        Block,
        Datatable,
    },
})
class Credit extends Vue {
    get buttons() {
        let buttons: any[] = []
        buttons.push('refresh')
        buttons.push('trashed')

        return buttons
    }

    get columns() {
        let columns: any[] = []
        // columns.push({
        //     data: '_id',
        //     title: 'Id',
        // })

        columns.push({
            data: 'user',
            title: 'Name',
            render: (data) => `${data?.firstname} ${data?.lastname}`
        })

        columns.push({
            data: 'createdAt',
            name: 'created_at',
            title: 'Created',
            render: CellRender.date()
        })

        columns.push({
            data: 'type',
            title: 'Type',
            render: (data) => {
                let style = ''
                switch (data) {
                    case Type.PENALTY:
                    case Type.CHARGE:
                    case Type.DEBIT:
                    case Type.AUTO:
                        style = 'text-danger'
                        break
                    case Type.CREDIT:
                        style = 'text-success'
                        break
                }
                return `<span class="${style} text-uppercase">${TransactionTypeLabel(data)}</span>`
            }
        })

        columns.push({
            data: 'meta.description',
            title: 'Description',
        })

        columns.push({
            data: 'status',
            title: 'Status',
            render: (data) => {
                let style = ''
                switch (data) {
                    case Status.FAILED:
                        style = 'text-danger'
                        break
                    case Status.SUCCESSFUL:
                        style = 'text-success'
                        break
                    case Status.PENDING:
                        style = 'text-warning'
                        break
                }
                return `<span class="${style} text-uppercase">${data}</span>`
            }
        })

        columns.push({
            data: 'amount',
            title: 'Amount',
            render: CellRender.money()
        })

        // columns.push({
        //     data: 'platform',
        //     title: 'Platform',
        //     orderable: true
        // })

        columns.push({
            data: 'platform',
            title: 'Platform Trxn ID',
            class: 'text-break',
            render: (_data, _type, row) => row.externalTransactionId || 'N/A'
        })

        columns.push({
            data: 'platform',
            title: 'Platform',
            class: 'text-center'
        })

        // columns.push({
        //     data: 'verified_at',
        //     title: 'Verified',
        //     orderable: true,
        //     render: CellRender.date()
        // })

        // columns.push({
        //     title: 'Admin',
        //     data: 'id',
        //     render: CellRender.admin(),
        // })

        return columns
    }

    get options() {
        return {
            ajax: {
                url: `${import.meta.env.VITE_CREDIT_API}/admin/credits/transactions`,
                method: 'GET',
            },
            buttons: this.buttons,
            columns: this.columns,
            order: [[5, 'desc']]
        }
    }
}

export default toNative(Credit)
</script>
