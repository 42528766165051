<template>
    <div class="row">
        <div class="col">
            <Block type="data">
                <template #header>
                    <h3 class="block-title">Stacs Vault Plans</h3>
                </template>
                <Datatable :options="options" />
            </Block>
        </div>
    </div>
</template>

<script lang="ts">
import type User from '@/models/User'
import Block from '@/vue/components/Block.vue'
import { CellRender } from '@/vue/components/Datatable'
import Datatable from '@/vue/components/Datatable.vue'
import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

@Component({
    components: {
        Datatable,
        Block
    }
})
class VaultTable extends Vue {
    @Prop({ type: Object })
    readonly user!: User

    get buttons() {
        let buttons: any[] = []
        buttons.push('refresh')
        buttons.push('trashed')


        return buttons
    }

    get columns() {
        let columns: any[] = []
        columns.push({
            data: 'id',
            orderable: true,
            name: 'id',
            title: 'Id',
        })
        columns.push({
            data: 'title',
            title: 'Title',
            name: 'title',
            render: CellRender.title(),
            orderable: true,
        })
        columns.push({
            data: 'amount',
            title: 'Capital',
            name: 'amount',
            render: CellRender.money(),
            orderable: true,
        })

        columns.push({
            data: 'balance',
            title: 'Balance',
            render: CellRender.money()
        })

        columns.push({
            data: 'created_at',
            title: 'Opened',
            name: 'created_at',
            render: CellRender.date(),
            orderable: true,
        })
        columns.push({
            data: 'yielded_at',
            title: 'Matured',
            render: CellRender.date(),
            orderable: true,
        })
        columns.push({
            data: 'completed_at',
            title: 'Closed',
            name: 'completed_at',
            render: CellRender.date(),
            orderable: true,
        })

        columns.push({
            title: 'Actions',
            data: 'url',
            render: CellRender.actions(null, { update: false, trash: false }),
        })

        return columns
    }

    get options() {
        return {
            ajax: {
                url: this.route('user.ajax.vault.table', { user: this.user.slug }),
                method: 'POST',
            },
            buttons: this.buttons,
            columns: this.columns,
        }
    }
}

export default toNative(VaultTable)
</script>
