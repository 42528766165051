import Admin, { hasRole, Role } from '@/models/Admin'
import Model from '@/models/Model'

export default class Gate {
	private policies: object

	constructor(private admin: Admin) {
		this.policies = {}

		const files = import.meta.glob('@/policies/*Policy*', { eager: true })
		for (let path in files) {
			let name = path
				.substring(path.lastIndexOf('/') + 1)
				.replace(/Policy\.ts$/, '')
				.toLowerCase()

			// @ts-ignore
			this.policies[name] = files[path]?.default
		}
	}

	/**
	 * Check if the admin has a general permission.
	 *
	 * @return {bool|void}
	 */
	before() {
		return hasRole(this.admin, Role.ADMIN)
	}

	/**
	 * Determine whether the admin can perform the action on the model.
	 *
	 * @param  {string}  action
	 * @param  {object|string}  model
	 * @return {bool}
	 */
	allow(action: string, model: Model | string) {
		if (this.before()) return true

		let type = typeof model === 'object' ? model.model : model

		// eslint-disable-next-line no-prototype-builtins
		if (this.admin && this.policies.hasOwnProperty(type) && typeof new this.policies[type]()[action] === 'function') {
			return new this.policies[type]()[action](this.admin, typeof model === 'object' ? model : null)
		}

		return false
	}

	/**
	 * Determine whether the admin can't perform the action on the model.
	 *
	 * @param  {string}  action
	 * @param  {object}  model
	 * @return {bool}
	 */
	deny(action: string, model: Model) {
		return !this.allow(action, model)
	}
}
