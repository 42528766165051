<template>
    <App title="RNPL">
        <Block>
            <template #header>
                <h3 class="block-title">RNPL</h3>
            </template>
            <Datatable ref="table" :scopes="scopes" :options="options" />
        </Block>
    </App>
</template>

<script lang="ts">
import Block from '@/vue/components/Block.vue'
import { CellRender, Column } from '@/vue/components/Datatable'
import Datatable from '@/vue/components/Datatable.vue'
import App from '@/vue/templates/App.vue'
import { Component, Vue, toNative } from 'vue-facing-decorator'

@Component({
    components: {
        App,
        Block,
        Datatable,
    },
})
class Group extends Vue {

    scopes = ["active", "finance", "operation"]

    get buttons() {
        let buttons: any[] = []
        buttons.push('refresh')
        buttons.push('trashed')
        // buttons.push({
        //     extend: 'searchBuilder',
        //     config: {
        //         ...SearchBuilder,
        //         columns: [0, 2, 6, 7]
        //     }
        // })

        // buttons.push({
        //     extend: 'searchPanes',
        //     config: {
        //         ...SearchPanes,
        //         columns: [2, 6, 7]
        //     }
        // })

        return buttons
    }

    get columns() {
        let columns: any[] = []
        columns.push({
            data: 'id',
            orderable: true,
            name: 'id',
            title: 'Id',
            type: 'num'
        })
        columns.push({
            data: 'user.fullname',
            title: 'User',
        })
        columns.push({
            data: 'amount',
            title: 'Amount',
            render: CellRender.money(),
            orderable: true,
            name: 'amount',
            type: 'num',
        })

        columns.push({
            data: 'status',
            title: 'Status',
        })

        columns.push({
            data: 'operation.status',
            title: 'Operations',
            render: CellRender.approval()
        })

        columns.push({
            data: 'finance.status',
            title: 'Finance',
            render: CellRender.approval()
        })

        columns.push({
            data: 'created_at',
            title: 'Submitted',
            render: CellRender.date(),
            orderable: true,
            name: 'created_at',
            type: 'date'
        })

        columns.push({
            data: 'completed_at',
            title: 'Completed',
            render: CellRender.date(),
            orderable: true,
            name: 'completed_at',
            type: 'date'
        })

        columns.push(Column.actions(this.$gate, { update: false }))

        return columns
    }

    get options() {
        return {
            ajax: {
                url: this.route('rnpl.ajax.table'),
                method: 'POST',
            },
            buttons: this.buttons,
            columns: this.columns,
            order: [[6, 'desc']]
        }
    }
}

export default toNative(Group)
</script>
