<template>
    <div ref="viewer" class="w-100 h-100">
        <img v-if="isImage" ref="image" :src="media.url" class="w-100 h-100 image" />
        <iframe v-else :src="media.url" class="w-100 h-100 iframe" />
    </div>
</template>

<script lang="ts">
import Media from '@/models/Media'
import Viewer from 'viewerjs'
import { Component, Prop, Vue, toNative } from "vue-facing-decorator"
import { Ref } from 'vue-property-decorator'

@Component({})
class Document extends Vue {
    image_viewer!: Viewer

    @Prop({ type: Object })
    readonly media!: Media

    @Ref()
    readonly image !: HTMLImageElement

    get isImage() {
        return this.media.type.includes("image")
    }

    mounted() {
        if (this.isImage)
            this.image_viewer = new Viewer(this.image, { inline: true })
    }

    destroyed() {
        if (this.isImage)
            this.image_viewer.destroy()
    }
}

export default toNative(Document)
</script>

<style scoped lang="scss">
@import "viewerjs/src/index";
</style>

<style scoped lang="scss">
.image {
    object-fit: contain;
}

.iframe {
    min-height: 600px;
}
</style>
