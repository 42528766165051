<template>
    <App title="Transactions">
        <Block>
            <template #header>
                <h3 class="block-title">Transactions</h3>
            </template>
            <Datatable :options="options" />
        </Block>
    </App>
</template>

<script lang="ts">
import Block from '@/vue/components/Block.vue'
import { CellRender, Column } from '@/vue/components/Datatable'
import Datatable from '@/vue/components/Datatable.vue'
import App from '@/vue/templates/App.vue'
import { Options, Vue } from 'vue-decorator'

@Options({
    components: {
        App,
        Block,
        Datatable,
    },
})
export default class Group extends Vue {
    get buttons() {
        let buttons: any[] = []
        buttons.push('refresh')
        buttons.push('trashed')

        return buttons
    }

    get columns() {
        let columns: any[] = []
        columns.push({
            data: 'id',
            orderable: true,
            name: 'id',
            title: 'Id',
        })

        columns.push({
            data: 'user.fullname',
            title: 'Name',
        })

        columns.push({
            data: 'type',
            title: 'Type',
            orderable: true,
            render: CellRender.payment()
        })

        columns.push({
            data: 'status',
            title: 'Status',
            orderable: true,
            name: 'status',
            render: CellRender.status()
        })

        columns.push({
            data: 'amount',
            title: 'Amount',
            render: CellRender.money()
        })

        columns.push({
            data: 'pid',
            name: 'pid',
            title: 'Trxn ID',
            class: 'text-break',
            orderable: true
        })

        columns.push({
            data: 'platform',
            name: 'platform',
            title: 'Platform',
            class: 'text-break',
            orderable: true
        })

        columns.push({
            data: 'narration',
            name: 'narration',
            title: 'Narration',
            class: 'text-break',
            orderable: true
        })

        columns.push({
            data: 'created_at',
            name: 'created_at',
            title: 'Created',
            orderable: true,
            render: CellRender.date()
        })

        columns.push(Column.actions(this.$gate, { update: false, view: false }))

        return columns
    }

    get options() {
        return {
            ajax: {
                url: this.route('transaction.ajax.table'),
                method: 'POST',
            },
            buttons: this.buttons,
            columns: this.columns,
            order: [[8, 'desc']]
        }
    }
}
</script>
