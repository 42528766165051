<template>
    <canvas ref="view" style="background-color: #fff;" />
</template>
<script lang="ts">
import variables from "@/scss/variables.module.scss"
import { Chart as BaseChart, ChartConfiguration, registerables } from 'chart.js'
import 'chartjs-adapter-moment'
import { merge } from 'lodash'
import { Component, Prop, Ref, Vue, toNative } from 'vue-facing-decorator'

BaseChart.register(...registerables)

@Component
class Chart extends Vue {
    chart !: BaseChart

    @Ref("view")
    readonly view!: HTMLCanvasElement

    @Prop({ type: Object, default: () => ({}) })
    readonly options!: ChartConfiguration

    get defaults(): ChartConfiguration {
        return {
            type: 'bar',
            data: {
                //@ts-ignore
                datasets: [{
                    backgroundColor: variables.primary,
                }]
            },
        }
    }

    mounted() {
        let opts = merge(this.defaults, this.options)
        this.chart = new BaseChart(this.view, opts)
    }

    destroyed() {
        this.chart.destroy()
    }
}

export default toNative(Chart)
</script>
