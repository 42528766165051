<template>
    <App title="Stacs Vault">
        <div class="row">
            <div class="col">
                <Block type="data">
                    <template #header>
                        <h3 class="block-title">Vault Details</h3>
                    </template>
                    <div class="row">
                        <div class="col-8">
                            <TextBlock label="Title">
                                {{ vault.title }}
                            </TextBlock>
                        </div>
                        <div class="col-4">
                            <TextBlock label="User">
                                <a class="text-accent1" :href="vault.user.url.single">{{ vault.user.fullname }}</a>
                            </TextBlock>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4">
                            <TextBlock label="Amount">
                                {{ vault.amount.formatted }}
                            </TextBlock>
                        </div>
                        <div class="col-4">
                            <TextBlock label="Income">
                                {{ vault.income.formatted }}
                            </TextBlock>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4">
                            <TextBlock label="Balance">
                                {{ vault.balance.formatted }}
                            </TextBlock>
                        </div>
                        <div class="col-4">
                            <TextBlock label="Earned">
                                {{ vault.earned.formatted }}
                            </TextBlock>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4">
                            <TextBlock label="Created Date">
                                {{ $render.date(vault.created_at) }}
                            </TextBlock>
                        </div>
                        <div class="col-4">
                            <TextBlock label="Maturity Date">
                                {{ $render.date(vault.yielded_at) }}
                            </TextBlock>
                        </div>
                        <div class="col-4">
                            <TextBlock label="Completed Date">
                                {{ $render.date(vault?.completed_at) }}
                            </TextBlock>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4">
                            <TextBlock label="Withdrawn">
                                {{ $render.bool(vault.hasWithdrawn) }}
                            </TextBlock>
                        </div>
                        <div class="col-4">
                            <TextBlock label="Interest Rate">
                                {{ vault.interest.data }}
                            </TextBlock>
                        </div>
                        <div class="col-4">
                            <TextBlock label="Extended">
                                {{ $render.bool(vault.isExtended) }}
                            </TextBlock>
                        </div>
                    </div>
                    <template #footer>
                        <button v-if="!vault.isCompleted" class="btn btn-tertiary text-danger" @click="onComplete">Complete Plan</button>
                        <button v-if="vault.isExtendable" class="btn btn-tertiary text-warning" @click="onExtend">Extend Plan</button>
                    </template>
                </Block>
            </div>
        </div>

        <Block type="data">
            <template #header>
                <h3 class="block-title">Transactions</h3>
            </template>
            <Datatable :options="options" />
        </Block>
    </App>
</template>
<script lang="ts">
import type Vault from '@/models/Vault'
import Block from '@/vue/components/Block.vue'
import Card from '@/vue/components/Card.vue'
import { CellRender } from '@/vue/components/Datatable'
import Datatable from '@/vue/components/Datatable.vue'
import TextBlock from '@/vue/components/TextBlock.vue'
import App from '@/vue/templates/App.vue'
import { AxiosError } from 'axios'
import { Options, Prop, Vue } from 'vue-decorator'

@Options({
    components: {
        App,
        Card,
        Block,
        TextBlock,
        Datatable
    },
})
export default class Single extends Vue {
    @Prop(Object)
    readonly vault!: Vault

    get buttons() {
        let buttons: any[] = []
        buttons.push('refresh')
        buttons.push('trashed')

        return buttons
    }

    get columns() {
        let columns: any[] = []
        columns.push({
            data: 'id',
            orderable: true,
            name: 'id',
            title: 'Id',
        })

        columns.push({
            data: 'amount',
            title: 'Amount',
            name: 'amount',
            orderable: true,
            render: CellRender.money()
        })

        columns.push({
            data: 'status',
            title: 'Status',
            orderable: true,
            name: 'status',
            render: CellRender.status
        })

        columns.push({
            data: 'created_at',
            name: 'created_at',
            title: 'Created',
            orderable: true,
            render: CellRender.date()
        })
        columns.push({
            data: 'verified_at',
            name: 'verified_at',
            title: 'Verified',
            orderable: true,
            render: CellRender.date()
        })

        return columns
    }

    get options() {
        return {
            ajax: {
                url: this.route('vault.ajax.transactions.table', { vault: this.vault.slug }),
                method: 'POST',
            },
            buttons: this.buttons,
            columns: this.columns,
        }
    }

    onComplete(e: Event) {
        e.preventDefault()
        const button = e.target as HTMLButtonElement
        button.disabled = true
        const label = button.innerHTML
        button.innerHTML = '<i class="fas fa-spinner fa-spin"></i>'

        this.$sweet.fire({
            title: 'Are You Sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',

            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'YES'
        }).then((result) => {
            if (result.isConfirmed) {
                this.$http
                    .patch(this.route('vault.ajax.complete', { vault: this.vault.slug }))
                    .then(() => this.$router.reload({ only: ['vault'] }))
                    .catch((e: AxiosError) => (this.$error(e), button.disabled = false, button.innerHTML = label))
            } else {
                button.disabled = false
                button.innerHTML = label
            }
        })
    }
    onExtend(e: Event) {
        e.preventDefault()
        const button = e.currentTarget as HTMLButtonElement
        button.disabled = true
        const label = button.innerHTML
        button.innerHTML = '<i class="fas fa-spinner fa-spin"></i>'
        this.$sweet.fire({
            title: 'Are You Sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',

            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'YES'
        }).then((result) => {
            if (result.isConfirmed) {
                this.$http
                    .patch(this.route('vault.ajax.extend', { vault: this.vault.slug }))
                    .then(() => this.$router.reload({ only: ['vault'] }))
                    .catch((e: AxiosError) => (this.$error(e), button.disabled = false, button.innerHTML = label))
            } else {
                button.disabled = false
                button.innerHTML = label
            }
        })
    }
}
</script>
