<template>
    <div class="row">
        <div class="col">
            <Block type="data">
                <template #header>
                    <h3 class="block-title">Pre-Qualifications</h3>
                </template>
                <Datatable :options="options" />
            </Block>
        </div>
    </div>
</template>

<script lang="ts">
import type User from '@/models/User'
import Block from '@/vue/components/Block.vue'
import { CellRender } from '@/vue/components/Datatable'
import Datatable from '@/vue/components/Datatable.vue'
import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

@Component({
    components: {
        Datatable,
        Block
    }
})
class QualificationTable extends Vue {
    @Prop({ type: Object })
    readonly user!: User

    get buttons() {
        let buttons: any[] = []
        buttons.push('refresh')
        buttons.push('trashed')


        return buttons
    }

    get columns() {
        let columns: any[] = []
        columns.push({
            data: 'id',
            orderable: true,
            name: 'id',
            title: 'Id',
        })

        columns.push({
            data: 'amount',
            title: 'Amount',
            name: 'amount',
            render: CellRender.money(),
            orderable: true,
        })

        columns.push({
            data: 'approval.status',
            title: 'Approval',
        })

        columns.push({
            data: 'created_at',
            title: 'Opened',
            name: 'created_at',
            render: CellRender.date(),
            orderable: true,
        })

        columns.push({
            data: 'expired_at',
            title: 'Expiry',
            name: 'expired_at',
            render: CellRender.date(),
            orderable: true,
        })

        columns.push({
            title: 'Actions',
            data: 'url',
            render: CellRender.actions(null, { update: false, trash: false }),
        })

        return columns
    }

    get options() {
        return {
            ajax: {
                url: this.route('user.ajax.qualification.table', { user: this.user.slug }),
                method: 'POST',
            },
            buttons: this.buttons,
            columns: this.columns,
        }
    }
}

export default toNative(QualificationTable)
</script>
